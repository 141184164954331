import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import toast from 'react-hot-toast'
import { useModal } from 'react-modal-hook'
import {
  useOrganization,
  useOrganizationUsers,
  useDeactivateOrganizationUserMutation,
  useSetOrganizationRoleMutation,
} from 'orgs-sites/org/api'
import {
  OrganizationUser,
  OrganizationRole,
  isOrgAdmin,
  organizationRoleFromGql,
  OrganizationStatus,
} from 'src/types'
import { DynamicTable, Button, Text, Spinner } from 'src/components/ui'
import { useAuth } from 'src/contexts/auth'
import {
  AddOrgUserModal,
  DeactivateUserModal,
  EnableUserModal,
  RemoveUserModal,
  UserType,
} from 'orgs-sites/users'
import { ErrorDisplay } from 'pages/app'
import { orgUsersTableConfig } from './OrgUsers.table.config'

enum OpenModal {
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  REMOVE_USER = 'REMOVE_USER',
  ADD_USER = 'ADD_USER',
  ENABLE_USER = 'ENABLE_USER',
  NONE = 'NONE',
}

export function OrgUsers(): JSX.Element {
  const [openModal, setOpenModal] = useState<OpenModal>(OpenModal.NONE)
  const selectedUserRef = useRef<OrganizationUser>()
  const { orgId } = useParams()
  if (!orgId) throw new Error('`orgId` route param missing')
  const { viewer } = useAuth()

  const usersQuery = useOrganizationUsers(orgId, true)
  const orgQuery = useOrganization(orgId)

  const deactivateOrgUserMutation = useDeactivateOrganizationUserMutation()
  const setOrgRoleMutation = useSetOrganizationRoleMutation()

  const [showAddOrgUserModal, closeAddOrgUserModal] = useModal(
    () =>
      orgQuery.data ? (
        <AddOrgUserModal org={orgQuery.data} onClose={closeAddOrgUserModal} />
      ) : null,
    [orgQuery.data],
  )

  const [showRemoveOrgUserModal, closeRemoveOrgUserModal] = useModal(
    () =>
      orgQuery.data && selectedUserRef.current ? (
        <RemoveUserModal
          type={UserType.ORG_USER}
          orgId={orgId}
          user={selectedUserRef.current}
          onClose={closeRemoveOrgUserModal}
          content={`Removing ${findUserName()} will prevent them from viewing your organization.
          They may still have access to some sites in your organization.`}
        />
      ) : null,
    [usersQuery.data],
  )
  const [showEnableOrgUserModal, closeEnableOrgUserModal] = useModal(
    () =>
      orgQuery.data && selectedUserRef.current ? (
        <EnableUserModal
          userName={findUserName()}
          onClose={closeEnableOrgUserModal}
          user={selectedUserRef.current}
        />
      ) : null,
    [usersQuery.data],
  )

  if (orgQuery.isLoading || usersQuery.isLoading) {
    return <Spinner />
  }

  if (usersQuery.error || orgQuery.error) {
    const errorQuery = usersQuery.error ? usersQuery : orgQuery
    return <ErrorDisplay error={errorQuery.error} action={errorQuery.refetch} />
  }

  const openDeactivateUserModal = (id: string): void => {
    const user = usersQuery.data.find(user => user.id === id)
    if (!user) return
    selectedUserRef.current = user
    setOpenModal(OpenModal.DEACTIVATE_USER)
  }

  async function deactivateUser(): Promise<void> {
    if (selectedUserRef.current) {
      await deactivateOrgUserMutation.mutateAsync(
        { id: selectedUserRef.current.id },
        {
          onSuccess: () => {
            toast.success(
              <Text>
                <span className="font-500">{`${
                  selectedUserRef.current?.name ||
                  selectedUserRef.current?.email
                } `}</span>
                has been deactivated.
              </Text>,
              { position: 'top-right' },
            )
          },
          onError: () => {
            closeModal()
            toast.error('Failed to deactivate the user.', {
              position: 'top-right',
            })
          },
        },
      )
    }
    closeModal()
  }

  function closeModal(): void {
    setOpenModal(OpenModal.NONE)
  }

  const handleChangeUserRole = async (
    role: OrganizationRole,
    userId: string,
  ): Promise<void> => {
    if (!orgId) return
    await setOrgRoleMutation.mutateAsync(
      {
        orgId,
        userId,
        role,
      },
      {
        onSuccess: data => {
          const name =
            data.setCustomerUserRole?.userEdge?.node?.name ?? 'Unknown'
          toast.success(
            <Text>
              <span className="font-500">{name}</span> role set to {role}
            </Text>,
            { position: 'top-right' },
          )
        },
        onError: () => {
          toast.error("Failed to change the user's organization role.", {
            position: 'top-right',
          })
        },
      },
    )
  }

  const findUserName = (): string => {
    const user = usersQuery.data.find(
      user => user.id === selectedUserRef.current?.id,
    )
    return user?.name || user?.email || ''
  }

  // FIXME changes to the viewer role are not visable until
  // refresh since the viewer details are cached in redux.
  const canDeactivateUser = (userOrgId: string): boolean => {
    // find the viewer's org role on the users org
    const viewerRole = viewer.customers?.items?.find(
      c => c?.id === userOrgId,
    )?.viewerRole
    // if the viewer is an admin for the user's org, they can deactivate
    return viewerRole ? isOrgAdmin(organizationRoleFromGql(viewerRole)) : false
  }

  const canRemoveUser = (userOrgId: string): boolean => {
    // get the viewers role for this org
    const viewerRole = viewer.customers?.items?.find(
      c => c?.id === orgId,
    )?.viewerRole
    // is the viewer an admin for this org?
    const isAdmin = viewerRole
      ? isOrgAdmin(organizationRoleFromGql(viewerRole))
      : false
    // can remove if the viewer is an admin and the user is from an external org
    return isAdmin && userOrgId !== orgId
  }

  const openRemoveUserModal = (id: string): void => {
    const user = usersQuery.data.find(user => user.id === id)
    if (!user) return
    selectedUserRef.current = user
    showRemoveOrgUserModal()
  }

  const openEnableUserModal = (id: string): void => {
    const user = usersQuery.data.find(user => user.id === id)
    if (!user) return
    selectedUserRef.current = user
    showEnableOrgUserModal()
  }

  return (
    <div className="mt-xs flex flex-1 flex-col">
      <div className="mb-xs flex items-center justify-between">
        <Text variant="description" className="my-xs">
          Lists all of the users assigned to this organization.
        </Text>
        {isOrgAdmin(orgQuery.data?.viewerRole) && (
          <Button
            variant="icon-primary"
            icon={regular('plus-circle')}
            title="Add User"
            onClick={showAddOrgUserModal}
          />
        )}
      </div>
      {orgQuery.data && orgId && (
        <DynamicTable
          id="OrgUsers"
          allowOverflow
          data={
            usersQuery.data.sort((a, b) => {
              if (a.id === viewer.id) return -1
              if (b.id === viewer.id) return 1
              if (
                a.status === OrganizationStatus.ACTIVE &&
                b.status === OrganizationStatus.INACTIVE
              )
                return -1
              if (
                a.status === OrganizationStatus.INACTIVE &&
                b.status === OrganizationStatus.ACTIVE
              )
                return 1
              return 0
            }) || []
          }
          headerSummary={`${
            usersQuery.data.filter(
              u => u.status !== OrganizationStatus.INACTIVE,
            ).length || 0
          } Users`}
          config={orgUsersTableConfig({
            canDeactivateUser,
            openDeactivateUserModal,
            canRemoveUser,
            openRemoveUserModal,
            openEnableUserModal,
            currentUser: usersQuery.data.find(u => u.id === viewer.id),
            orgId,
            onRoleChange: handleChangeUserRole,
          })}
          rowHeight={48}
        />
      )}
      <DeactivateUserModal
        userName={findUserName()}
        onConfirm={deactivateUser}
        isOpen={openModal === OpenModal.DEACTIVATE_USER}
        onClose={closeModal}
      />
    </div>
  )
}
