import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DynamicTable, Icon, Text } from 'src/components/ui'
import { useMySites } from 'orgs-sites/site/api'
import { useSite } from 'src/contexts/site'
import { useAuth } from 'src/contexts/auth'
import {
  useUpdateFactoryTrendView,
  useDeleteFactoryTrendView,
  useFactoryTrendViews,
} from 'trend/api'
import { EditViewModal, DeleteViewModal } from './components'
import { getTrendViewsTableConfig } from './trendViewsTable.config'

export function TrendViewsPage(): JSX.Element {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false)
  const [viewId, setViewId] = useState<string>('')
  const { id: currentFactory } = useSite()
  const { viewer } = useAuth()
  const navigate = useNavigate()

  const mySitesQuery = useMySites()
  const trendViewQuery = useFactoryTrendViews()
  const deleteViewMutation = useDeleteFactoryTrendView()
  const updateViewMutation = useUpdateFactoryTrendView()

  function handleNavigateToCreatedTrendView(data: any): void {
    navigate(`/site/${currentFactory}/trend/${data.id}`)
  }

  async function handleEditView({
    name,
    shared,
  }: {
    name: string
    shared: boolean
  }): Promise<void> {
    const currentView = trendViewQuery.data?.find(v => v.id === viewId)
    if (!currentView) return

    await updateViewMutation.mutateAsync(
      {
        data: currentView.data,
        name,
        shared,
        trendViewId: viewId,
      },
      {
        onError: () => {
          setIsEditOpen(false)
          setTimeout(() => {
            toast.error('Failed to update the trend view', {
              position: 'top-right',
            })
          }, 300)
        },
      },
    )
    setIsEditOpen(false)
  }

  async function handleDeleteView(): Promise<void> {
    await deleteViewMutation.mutateAsync(
      { trendViewId: viewId },
      {
        onError: () => {
          setIsDeleteOpen(false)
          setTimeout(() => {
            toast.error('Failed to delete the trend view', {
              position: 'top-right',
            })
          }, 300)
        },
      },
    )
    setIsDeleteOpen(false)
  }

  const site = mySitesQuery.data?.find(site => site.id === currentFactory)
  const selectedView = trendViewQuery.data?.find(el => el.id === viewId)
  return (
    <div className="m-[1em] flex h-[calc(100%-2em)] flex-1 flex-col rounded-2xs bg-background px-s">
      <div className="flex items-center gap-l px-xs py-m">
        <Icon
          icon={light('industry-windows')}
          className="!h-[30px] !w-[36px] text-icon-secondary"
        />
        <div>
          <Text variant="description" className="mb-2xs">
            Site
          </Text>
          <Text variant="content" bold>
            {site?.name}
          </Text>
        </div>
        <div>
          <Text variant="description" className="mb-2xs">
            Organization
          </Text>
          <Text variant="content" bold>
            {site?.orgName}
          </Text>
        </div>
      </div>
      <Text variant="description" className="pb-s">
        Your <span className="font-500">Saved Views</span> available for this
        site.
      </Text>
      <DynamicTable
        id="factoryTrendViews"
        data={trendViewQuery.data ?? []}
        headerSummary={`${trendViewQuery.data?.length ?? '0'} Views`}
        config={getTrendViewsTableConfig({
          actions: {
            delete: (id: string) => {
              setViewId(id)
              setIsDeleteOpen(true)
            },
            edit: (id: string) => {
              setViewId(id)
              setIsEditOpen(true)
            },
          },
          userId: viewer?.id ?? '',
          baseLink: `/site/${currentFactory}/trend`,
        })}
        actions={{ selectRow: handleNavigateToCreatedTrendView }}
      />
      <EditViewModal
        isLoading={updateViewMutation.isLoading}
        viewName={selectedView?.name ?? ''}
        siteName={site?.name ?? ''}
        shared={selectedView?.shared ?? false}
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        onConfirm={handleEditView}
      />
      <DeleteViewModal
        isLoading={deleteViewMutation.isLoading}
        viewName={selectedView?.name ?? ''}
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        onConfirm={handleDeleteView}
      />
    </div>
  )
}
