import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import toast from 'react-hot-toast'
import { useSite } from 'src/contexts/site'
import { Asset, assetTitle, Tag, SiteRole } from 'src/types'
import { useTitle } from 'src/utility'
import { Button, ResizableLayout, Text, Tooltip } from 'src/components/ui'
import { useAssetHierarchy } from 'src/contexts/assetHierarchy/useAssetHierarchy'
import { AssetHierarchyProvider } from 'src/contexts/assetHierarchy/AssetHierarchyProvider'
import { useTags, useUpdateTagsMutation } from 'tags/api'
import {
  useAssets,
  useCreateAssetMutation,
  useDeleteAssetMutation,
  useUpdateAssetMutation,
  useUpdateHierarchyAssetsMutation,
} from './api'
import { AssetItem } from './assets.types'
import './assets.styles.css'
import {
  ButtonAdd,
  TagsActionBar,
  AssetActionBar,
  TagTableContainer,
  AssetTagTable,
  PopUpTagList,
  AssetsHierarchy,
  ConfirmationModal,
  EditAssetNameModal,
  CreateAssetModal,
  DeleteAssetModal,
} from './components'

const getNodeKey = ({ node }: { node: AssetItem }): string =>
  node.asset.key.toString()

// sets expanded: false for all items in the tree
const collapse = (tree: AssetItem[]): AssetItem[] => {
  return tree.map(item => ({
    ...item,
    expanded: false,
    // children is alway an array in our implementation
    children: Array.isArray(item.children)
      ? collapse(item.children)
      : item.children,
  }))
}

// a simple version of AssetItem
type Item = {
  title: string
  expanded: boolean
  children: Item[]
  asset: Asset
}

const toReactSortableTree = (items: Item[]): Item[] => {
  const keys = items.map(node => node.asset.key)
  const roots = items.filter(node => keys.indexOf(node.asset.parentKey) === -1)

  let nodesarray = [...roots]

  while (nodesarray.length > 0) {
    const node = nodesarray.pop()
    if (node) {
      const children = items
        .filter(x => x.asset.parentKey === node.asset.key)
        .sort((a, b) => {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        })

      node.children = [...node.children, ...children]
      nodesarray = [...nodesarray, ...children]
    }
  }

  return roots
}

// Map objects to React Sortable Tree structure
// Specifically for asset arrays
const mapToRST = (
  assets: Asset[],
  expandedAssets: Set<number>,
): AssetItem[] => {
  // react sortable tree
  const rsTree: Item[] = assets
    .filter(asset => asset.active)
    .map(asset => ({
      title: assetTitle(asset),
      expanded: expandedAssets.has(asset.key) || false,
      children: [],
      asset,
    }))

  const rstRoots = toReactSortableTree(rsTree)

  // root node expanded so user doesn't have to manually open it
  if (rstRoots.length > 0) {
    rstRoots[0].expanded = true
  }
  return rstRoots
}

type AssetModalName = 'Delete' | 'Edit' | 'Create'

type ShowAssetModal = {
  show: true
  modal: AssetModalName
  asset: Asset
}

type HideAssetModal = {
  show: false
}

type AssetModal = ShowAssetModal | HideAssetModal

type ShowMoveTagsModal = {
  show: true
  asset: Asset
  tags: Tag[]
}

type HideMoveTagsModal = {
  show: false
}

type MoveTagsModal = ShowMoveTagsModal | HideMoveTagsModal

function Assets(): JSX.Element {
  useTitle('Assets')
  const updateTags = useUpdateTagsMutation()
  const updateAsset = useUpdateAssetMutation()
  const deleteAsset = useDeleteAssetMutation()
  const createAsset = useCreateAssetMutation()
  const updateHierarchyAssets = useUpdateHierarchyAssetsMutation()
  const assetsQuery = useAssets()
  const assets = useMemo(() => assetsQuery.data || [], [assetsQuery.data])
  const { data: tags = [] } = useTags()

  const { viewerRole } = useSite()
  const isReader = viewerRole === SiteRole.READER

  const { id: targetId, setSelectedTags } = useAssetHierarchy()

  // selected asset from the asset hierarchy
  const [selectedAsset, setSelectedAsset] = useState<Asset>()
  // tags under selected Asset
  // only update if tags or selectedAsset change
  const assetTags = useMemo(
    () =>
      selectedAsset ? tags.filter(t => t.parentKey === selectedAsset.key) : [],
    [selectedAsset, tags],
  )

  // show popup/modals
  const [showPopUpTagList, setShowPopUpTagList] = useState(false)
  const [moveTagsModal, setMoveTagsModal] = useState<MoveTagsModal>({
    show: false,
  })
  const [assetModal, setAssetModal] = useState<AssetModal>({
    show: false,
  })

  // react sortable tree data
  const [treeData, setTreeData] = useState<AssetItem[]>([])
  // tracks moved assets
  const [movedAssets, setMovedAssets] = useState<Asset[]>([])
  // tracks which nodes are expanded to persist the expanded state
  const [expandedAssets, setExpandedAssets] = useState<Set<number>>(new Set())

  // Reset selected tags when toggling all tags modal
  useEffect(() => {
    setSelectedTags([])
  }, [showPopUpTagList, setSelectedTags])

  // recreate the tree if assets gets updated
  useEffect(() => {
    setTreeData(mapToRST(assets, expandedAssets))
    // WARNING! exhaustive-deps is disabled, because we don't want expandedAssets
    // in the dependency list, because we don't want changes to expandedAssets
    // to trigger a reload of the tree. It is only used as helper information
    // when the tree is created
    // useEventEffect looks like it will fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets])

  const collapseAssetTree = (): void => {
    const collapsedTree = collapse(treeData)
    if (collapsedTree.length > 0) {
      collapsedTree[0].expanded = true
    }
    setTreeData(collapsedTree)
  }

  // Implement expandedAssets in mapToRST to persist expanded.
  // only called when asset node is expanded
  const onExpandToggle = (asset: Asset, expanded: boolean): void => {
    const updatedExpandedAssets = new Set(expandedAssets)
    if (expanded) {
      updatedExpandedAssets.add(asset.key)
    } else {
      updatedExpandedAssets.delete(asset.key)
    }
    setExpandedAssets(updatedExpandedAssets)
  }

  const handleUpdateDrop = (tags: any[]): void => {
    const asset = assets.find(a => a.assetId === targetId)
    if (asset && tags.length) setMoveTagsModal({ show: true, asset, tags })
  }

  const closeMoveTagsModal = (): void => setMoveTagsModal({ show: false })

  const onClickConfirmMoveTags = async (
    asset: Asset,
    tags: Tag[],
  ): Promise<void> => {
    // update the parent of the selected tags
    const updatedTags = tags.map(tag => ({
      ...tag,
      parentKey: asset.key,
      parentName: asset.name,
    }))
    await updateTags.mutateAsync(updatedTags, {
      onSuccess: () => {
        closeMoveTagsModal()
        setTimeout(() => {
          toast.success('Tags moved successfully', { position: 'top-right' })
        }, 300)
      },
      onError: () => {
        closeMoveTagsModal()
        setTimeout(() => {
          toast.error('Failed to move tags', { position: 'top-right' })
        }, 300)
      },
    })
    closeMoveTagsModal()
  }

  const closeAssetModal = (): void => setAssetModal({ show: false })

  const showAssetModal = (modal: AssetModalName, asset: Asset): void =>
    setAssetModal({ show: true, modal, asset })

  const resetTreeState = (): void => {
    setTreeData(mapToRST(assets, expandedAssets))
    setMovedAssets([])
  }

  const saveTreeState = (): void => {
    updateHierarchyAssets.mutate(movedAssets)
    setMovedAssets([])
  }

  const addMovedAsset = (movedAsset: Asset): void => {
    const { key, parentKey } = movedAsset
    const keys = movedAssets.map(asset => asset.key)

    if (keys.includes(key)) {
      const existId = keys.indexOf(key)
      const movedAssetsCopy = movedAssets.slice()
      movedAssetsCopy[existId] = movedAsset
      setMovedAssets(movedAssetsCopy)
    } else {
      setMovedAssets([...movedAssets, movedAsset])
      setExpandedAssets(new Set(expandedAssets.add(parentKey)))
    }
  }

  const onClickAddChildAsset = (asset: Asset, name: string): void => {
    createAsset.mutate({ name, parentKey: asset.key })
    closeAssetModal()
  }

  const onClickDeleteAsset = (asset: Asset): void => {
    deleteAsset.mutate(asset)
    closeAssetModal()
  }

  const updateAssetName = (asset: Asset, newName: string): void => {
    updateAsset.mutate({ id: asset.assetId, alias: newName })
    closeAssetModal()
  }

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      {assetModal.show && assetModal.modal === 'Delete' && (
        <DeleteAssetModal
          onClickConfirm={onClickDeleteAsset}
          onClickCancel={() => closeAssetModal()}
          onClickCross={() => closeAssetModal()}
          asset={assetModal.asset}
          hasChildren={assets.some(a => a.parentKey === assetModal.asset.key)}
          hasTags={tags.some(t => t.parentKey === assetModal.asset.key)}
        />
      )}

      {assetModal.show && assetModal.modal === 'Create' && (
        <CreateAssetModal
          onClickConfirm={name => onClickAddChildAsset(assetModal.asset, name)}
          onClickCancel={() => closeAssetModal()}
          onClickCross={() => closeAssetModal()}
          assets={assets}
          parentAsset={assetModal.asset}
        />
      )}

      {assetModal.show && assetModal.modal === 'Edit' && (
        <EditAssetNameModal
          onClickConfirm={name => updateAssetName(assetModal.asset, name)}
          onClickCross={() => closeAssetModal()}
          onClickCancel={() => closeAssetModal()}
          asset={assetModal.asset}
          assets={assets}
        />
      )}

      {moveTagsModal.show && (
        <ConfirmationModal
          isPending={updateTags.isLoading}
          onClickConfirm={() =>
            onClickConfirmMoveTags(moveTagsModal.asset, moveTagsModal.tags)
          }
          onClickCancel={closeMoveTagsModal}
          onClickCross={closeMoveTagsModal}
        >
          Move {moveTagsModal.tags.length}{' '}
          {moveTagsModal.tags.length === 1 ? 'tag' : 'tags'} to{' '}
          <b>{assetTitle(moveTagsModal.asset)}</b> ?
        </ConfirmationModal>
      )}

      <ResizableLayout
        defaultSize={30}
        minSize={600}
        leftComponent={
          <>
            <AssetActionBar>
              <div className="flex items-center gap-xs">
                <Text variant="title" className="text-text-tertiary">
                  Modify Asset Hierarchy
                </Text>
                <Tooltip
                  direction="bottom"
                  render={() => (
                    <>
                      <Text className="mb-xs">
                        <span className="font-500">The Asset Hierarchy </span>
                        is where you modify how your plant/factory is logically
                        structured. This is not necessarilly how everything
                        impacts everything, but broken down into main
                        activities/lines and then further broken down into the
                        individual assets.
                      </Text>
                      <Text className="mb-xs">
                        An asset in this context is for example a motor, a pump
                        or a tank, but could also be a subprocess like steam,
                        pressurised air or cold storage. The main activities is
                        to arrange tags under the right asset and to verify that
                        the Label/data-type is correct. It also serve as a
                        starting point to create machine learning models.
                      </Text>
                      <Text>
                        <span className="font-500">WARNING:</span> when multiple
                        users are editing the same asset(s) at the same time,
                        committing any changes will potentially overwrite any
                        changes made by other users.
                      </Text>
                    </>
                  )}
                />
              </div>
              <div className="flex items-center gap-xs">
                <Button
                  variant="primary"
                  title="Save Assets"
                  disabled={movedAssets.length === 0 || isReader}
                  onClick={saveTreeState}
                />
                <Button
                  variant="icon-primary"
                  icon={light('redo-alt')}
                  title="Reset"
                  disabled={movedAssets.length === 0 || isReader}
                  onClick={resetTreeState}
                />
              </div>
            </AssetActionBar>
            <AssetsHierarchy
              treeData={treeData}
              selectedAsset={selectedAsset}
              getNodeKey={getNodeKey}
              selectAsset={asset => setSelectedAsset(asset)}
              setTreeState={data => setTreeData(data)}
              addMovedAsset={addMovedAsset}
              onClickCollapseAll={collapseAssetTree}
              onExpandToggle={onExpandToggle}
              handleUpdateDrop={handleUpdateDrop}
              /* Context Menu */
              onClickAddChildAsset={asset => showAssetModal('Create', asset)}
              onClickDeleteAsset={asset => showAssetModal('Delete', asset)}
              onClickChangeAssetName={asset => showAssetModal('Edit', asset)}
            />
          </>
        }
        rightComponent={
          <div className="flex h-full flex-1 flex-col">
            <TagsActionBar>
              <Text className="!text-lg">
                TAGS FOR:{' '}
                <span className="text-xl font-500 text-text-success">
                  {selectedAsset ? assetTitle(selectedAsset) : 'Unknown'}
                </span>
              </Text>
              <ButtonAdd>
                <Button
                  variant="primary"
                  title="All Tags"
                  onClick={() => setShowPopUpTagList(true)}
                />
              </ButtonAdd>
            </TagsActionBar>
            <TagTableContainer>
              <AssetTagTable
                assetTags={assetTags}
                shouldDeselect={showPopUpTagList}
              />
              {showPopUpTagList && (
                <PopUpTagList
                  tags={tags}
                  onClickCross={() => setShowPopUpTagList(false)}
                />
              )}
            </TagTableContainer>
          </div>
        }
      />
    </DndProvider>
  )
}

export function AssetsPage(): JSX.Element {
  return (
    <AssetHierarchyProvider>
      <Assets />
    </AssetHierarchyProvider>
  )
}
